<template>
    <div class="survey__question">
        <v-card flat class="u-border-bottom">
            <v-card-title>
                <v-flex grow>
                    <h2 class="subtitle-1" style="font-weight: 500"><span
                        style="word-break: break-word; word-wrap: break-word; max-width:75%; display:inline-block;"
                        v-if="getLocalizedValue(question.question)">{{ getLocalizedValue(question.question) }}</span>
                    </h2>
                </v-flex>
                <v-flex shrink class="text-right ml-2" v-if="tooltipText.length > 0">
                    <v-tooltip v-model="showTooltip" top>
                        <template v-slot:activator="{on, attrs}">
                            <v-btn icon @click="showTooltip = !showTooltip">
                                <v-icon color="grey lighten-1">help_outline</v-icon>
                            </v-btn>
                        </template>
                        <span>{{ tooltipText }}</span>
                    </v-tooltip>
                </v-flex>
                <v-flex shrink class="text-right ml-2">
                    <span class="float-right status"
                          :class="{'status--required': questionRequired, 'status--optional': !questionRequired,}">
                        {{ questionRequired ? `${this.$t('survey_question.required')}` : `${this.$t('survey_question.optional')}` }}</span>
                </v-flex>
            </v-card-title>
            <v-card-text>
                <survey-question-info :question="question"/>
                <component v-show="question.inputType && question.isVisible"
                           :is-required="question.isRequired"
                           :placeholder-text="placeholderText"
                           :confirmText="getLocalizedValue(question.confirmText)"
                           :confirmUrl="getLocalizedValue(question.confirmUrl)"
                           :options="question.options" :hintText="hintText"
                           :min="question.min" :max="question.max" :questionId="question.id" :is="fieldTypeComponent"
                           @updateAnswer="onUpdateAnswer"/>
            </v-card-text>
        </v-card>
    </div>
</template>

<script>
import {mapState, mapMutations} from 'vuex';
import SurveyQuestionInfo from './SurveyQuestionInfo';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'SurveyViewQuestion',
    components: {SurveyQuestionInfo},
    props: {
        question: {
            type: Object,
            required: true
        }
    },
    mixins: [
        GetLocalizedValue
    ],
    data: () => ({
        showTooltip: false
    }),
    computed: {
        ...mapState('surveys', ['currentSurveyAnswers']),
        questionRequired() {
            return this.question.isRequired;
        },
        tooltipText() {
            return this.getLocalizedValue(this.question.helpDetail, false) || '';
        },
        hintText() {
            return this.getLocalizedValue(this.question.helpShort, false);
        },
        placeholderText() {
            let placeholder = this.getLocalizedValue(this.question.placeholder, false);
            if (!placeholder) {
                placeholder = this.$t('survey_question.empty_placeholder');
            }
            return placeholder;
        },
        fieldTypeComponent() {
            const fieldType = this.question.inputType;
            let component;
            switch (fieldType) {
            case 'STRING':
                component = 'String';
                break;
            case 'NUMERIC':
                component = 'Numeric';
                break;
            case 'RADIO':
                component = 'Radio';
                break;
            case 'CHECKBOX':
                component = 'Checkbox';
                break;
            case 'DROPDOWN':
                component = 'DropDownList';
                break;
            case 'DATE':
                component = 'Date';
                break;
            case 'DATETIME':
                component = 'DateTime';
                break;
            case 'BOOLEAN':
                component = 'TrueFalse';
                break;
            case 'MEDIA':
                component = 'Media';
                break;
            case 'IMO':
                component = 'IMO';
                break;
            case 'COSTESTIMATE':
                component = 'CostEstimate';
                break;
            case 'CALLSIGN':
                component = 'Callsign';
                break;
            case 'DAMAGE':
                component = 'Damage';
                break;
            case 'CONFIRM':
                component = 'Confirm';
                break;
            default:
                component = 'String';
            }

            return () => import(`../FieldTypes/${component}.vue`);
        }
    },
    methods: {
        ...mapMutations('surveys', ['addOrUpdateSurveyAnswer', 'deleteQuestionFromSurveyAnswers']),
        onUpdateAnswer(answer) {
            const valueLengthCheck = answer.toString().length > 0;
            if (valueLengthCheck) {
                this.addOrUpdateSurveyAnswer({
                    id: this.question.id,
                    value: answer
                });
            }
            if (answer.toString().length === 0) {
                this.deleteQuestionFromSurveyAnswers(this.question.id);
            }
        }
    }
};
</script>
