<template>
    <div class="survey-answer">
        <template v-for="answer in answers">
            <div v-for="(option, index) in answer" :key="index">
                {{getLocalizedValue(option.answer)}}
            </div>
        </template>
    </div>
</template>

<script>
import GetLocalizedValue from "../../../../mixins/GetLocalizedValue.vue";

export default {
    name: 'AnswerOption',
    mixins: [
        GetLocalizedValue
    ],
    props: {
        answers: {
            required: true
        }
    }
};
</script>
