<template>
    <div class="survey-answer">
        <div v-for="(answer, index) in answers" :key="index">
            {{answer | formatDate}}
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnswerDate',
    props: {
        answers: {
            required: true
        }
    }
};
</script>
