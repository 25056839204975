<template>
    <div class="survey-answer">
        <template >
            <div v-for="(answer, index) in answers" :key="index">
                {{answer}}
            </div>
        </template>
    </div>
</template>

<script>
export default {
    name: 'AnswerText',
    props: {
        answers: {
            required: true
        }
    }
};
</script>
