<template>
    <div>
        <div class="d-flex flex-wrap" v-for="(option, index) in answers" :key="index">
            <div>
                {{option.answer}}
            </div>
        </div>
    </div>
</template>

<script>
import constants from '../../../../helpers/constants';
export default {
    name: 'AnswerDamage',
    props: {
        answers: {
            required: true
        }
    },
    data: () => ({
        imagePath: constants.cloudinaryUrlSmall,
        videoUrl: constants.cloudinaryVideo
    })
};
</script>
