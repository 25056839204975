<template>
    <v-card flat class="survey__page">
        <v-card-title>{{getLocalizedValue(section.title)}}</v-card-title>
        <v-card-text class="px-0 pa-0">
            <survey-view-question v-for="question in section.children" :key="question.id" :question="question" />
        </v-card-text>
    </v-card>
</template>

<script>
import SurveyViewQuestion from './SurveyViewQuestion';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';
export default {
    name: 'SurveyViewSection',
    props: {
        section: {
            type: Object,
            required: true
        }
    },
    mixins: [
        GetLocalizedValue
    ],
    components: {SurveyViewQuestion}
};
</script>
