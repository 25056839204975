<template>
    <div class="survey-answer">
        <v-list style="background:transparent;" class="px-0">
            <template v-for="(answer, idx) in answers">
                <v-list-item v-for="(image, index) in answer.slice(0, 7)" :key="index" style="background:transparent;" class="px-0">
                    <v-list-item-avatar>
                        <v-avatar :size="50">
                            <img :src="imageUrl + image.name" />
                        </v-avatar>
                    </v-list-item-avatar>
                </v-list-item>
                <v-list-item v-if="answer.length > 7" :key="idx">
                    <v-list-item-content>
                        and {{answer.length - 7}} more images...
                    </v-list-item-content>
                </v-list-item>
            </template>
        </v-list>
    </div>
</template>

<script>
import constants from '../../../../helpers/constants';

export default {
    name: 'AnswerMedia',
    props: {
        answers: {
            required: true
        }
    },
    data: () => ({
        imageUrl: constants.cloudinaryUrlSmall
    })
};
</script>
