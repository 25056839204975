<template>
    <div class="survey">
        <loading v-if="isLoadingSurvey"/>
        <template v-if="!isLoadingSurvey">
            <v-toolbar color="primary" dark flat>
                <v-btn icon @click="goBack">
                    <v-icon large>arrow_back</v-icon>
                </v-btn>
                <v-toolbar-title v-if="surveyInstance">{{getLocalizedValue(surveyInstance.title)}}</v-toolbar-title>
                <v-spacer></v-spacer>
            </v-toolbar>
            <survey-view-details :survey="surveyInstance"/>
            <v-alert type="error" tile icon="error_outline" v-if="isError">
                {{$t("survey.problem_submitting")}}<br/>
                <template v-if="surveyErrors.length === 0">
                    {{errorText}}
                </template>
                <div v-for="(error, index) in surveyErrors" :key="index">
                    <i18n path="survey.error_message_for_question" tag="span">
                        <template v-slot:question>{{getLocalizedValue(error.question)}}</template>
                        <template v-slot:error>{{$t(error.error)}}</template>
                        <template v-slot:value>{{ error.value ? error.value.toString() : '' }}</template>
                    </i18n>
                </div>
            </v-alert>
            <div class="survey-details__buttons pb-3 px-3 mt-3">
                <div class="text-right">
                    <v-btn :loading="isLoading" color="primary" rounded depressed @click="onSubmit">{{$t(submitText)}}</v-btn>
                </div>
            </div>

            <modal-error-uploading-images v-if="errorUploadingImages"
                                          :error-message="errorMessageUploadImages"
                                          @closeModalIsErrorImages="errorUploadingImages = false"/>
            <modal-is-uploading-images @allFilesUploaded="onAllFilesUploaded"
                                       @allFilesUploadedFailed="onAllFilesUploadedFailed"
                                       v-if="isUploadingFiles"/>
            <modal-survey-created v-if="showModalSurveyCreated"/>

        </template>
    </div>
</template>

<script>
import {mapMutations, mapState} from 'vuex';
import SurveyViewDetails from '../../../components/Surveys/SurveyView/SurveyViewDetails';
import ModalIsUploadingImages from '../../../components/Surveys/Modals/ModalIsUploadingImages';
import * as NoSleep from '@hacksawstudios/nosleep.js/dist/NoSleep.min';
import ModalErrorUploadingImages from '../../../components/Surveys/Modals/ModalErrorUploadingImages';
import ModalSurveyCreated from '../../../components/Surveys/Modals/ModalSurveyCreated';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'SurveyView',
    components: {ModalSurveyCreated, ModalErrorUploadingImages, SurveyViewDetails, ModalIsUploadingImages},
    mixins: [GetLocalizedValue],
    props: {
        surveyVersion: {
            type: Number
        }
    },
    data: () => ({
        isLoadingSurvey: false,
        isLoading: false,
        isError: false,
        errorText: '',
        surveyInstanceVersion: null,
        surveyInstanceId: null,
        surveyInstance: null,
        wakeLockEnabled: false,
        surveyErrors: [],
        isUploadingFiles: false,
        showErrorInForm: false,
        errorUploadingImages: false,
        errorMessageUploadImages: '',
        noSleep: new NoSleep()
    }),
    computed: {
        ...mapState('surveys', ['currentSurveyVersion', 'surveys']),
        ...mapState('modals', ['showModalSurveyCreated']),
        submitText() {
            return 'survey.resubmit';
        }
    },
    methods: {
        ...mapMutations('surveys', ['savePendingSurvey', 'updateFilesToQuestion', 'setCurrentSurvey', 'setSurveyMode']),
        onAllFilesUploaded() {
            this.isUploadingFiles = false;
            this.showCompletedModal();
        },
        onAllFilesUploadedFailed(message) {
            this.isUploadingFiles = false;
            this.errorMessageUploadImages = message;
            this.showErrorImagesModal();
        },
        showErrorImagesModal() {
            this.savePendingSurvey();
            this.errorUploadingImages = true;
        },
        showCompletedModal() {
            this.$store.dispatch('modals/toggleModal', 'showModalSurveyCreated');
        },
        goBack() {
            this.$router.back();
        },
        findAndSetSurvey() {
            const currentSurvey = this.surveys.find(survey => survey.survey.id === this.$route.params.surveyId);
            this.setCurrentSurvey(currentSurvey);
        },
        fetchSurvey(version = null) {
            console.log('fetch survey');
            this.isLoadingSurvey = true;
            const survey = {
                id: this.$route.params.surveyId,
                version: version
            };
            this.$store.dispatch('surveys/getSurveyInstanceByVersion', survey).then((data) => {
                this.surveyInstance = JSON.parse(data.survey);
                this.surveyInstanceId = data.instanceId;
                this.surveyInstanceVersion = data.instanceVersion;
                const currentSurvey = {
                    survey: this.surveyInstance
                };
                this.setCurrentSurvey(currentSurvey);
                this.isLoadingSurvey = false;
                this.isError = false;
            }).catch((err) => {
                console.log('error error', err);
                this.isError = true;
            });
        },
        fetchSurveyVersion() {
            this.$store.dispatch('surveys/getSurveyInstance', this.$route.params.surveyId).then((data) => {
                if (data.version) {
                    this.fetchSurvey(data.version);
                }
            });
        },
        toggleWakeLock() {
            if (!this.wakeLockEnabled) {
                this.noSleep.enable(); // keep the screen on!
                this.wakeLockEnabled = true;
            } else {
                this.noSleep.disable(); // let the screen turn off.
                this.wakeLockEnabled = false;
            }
        },
        onSubmit() {
            this.isLoading = true;
            const payload = {
                instanceId: this.surveyInstanceId,
                instanceVersion: this.surveyInstanceVersion
            };
            this.$store.dispatch('surveys/submitSurvey', payload).then((response) => {
                this.isLoading = false;
                const errors = response.surveySubmit.errors;
                const allUploads = response.surveySubmit.uploads;

                if (errors) {
                    this.isError = true;
                    this.isLoading = false;
                    this.errorText = this.$t('survey.error_missing_required');
                    this.surveyErrors = [...errors];
                } else {
                    this.isError = false;
                }

                if (allUploads) {
                    allUploads.forEach((upload, index) => {
                        const instanceId = response.surveySubmit.message;
                        const answerId = upload.answerId;
                        const filePath = upload.path;
                        const fileType = upload.fileType;
                        const questionId = upload.questionId;
                        this.updateFilesToQuestion({
                            instance: instanceId,
                            uploads: allUploads,
                            answerId: answerId,
                            path: filePath,
                            questionId: questionId,
                            fileType: fileType,
                            optionId: upload.optionId
                        });

                        if (index === allUploads.length - 1) {
                            this.isUploadingFiles = true;
                            this.toggleWakeLock();
                        }
                    });
                }
                if (!allUploads && !this.isError) {
                    this.showCompletedModal();
                }
            }).catch((err) => {
                this.errorText = err;
                this.isError = true;
            });
        }
    },
    created() {
        /**
         * If no version is passed, get the version, and load survey (occours if user reloads page)
         */
        this.setSurveyMode('resubmit-survey');
        this.fetchSurveyVersion();
        /* if (this.surveyVersion) {
            this.fetchSurvey();
        } else {
            this.fetchSurveyVersion();
        } */
    }
};
</script>
