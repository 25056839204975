<template>
    <v-card flat class="survey-details" v-if="survey">
        <v-card-text class="pa-0 px-0">
            <v-window>
                <div v-for="page in survey.children" :key="page.id">
                    <survey-view-page :page="page"/>
                </div>
            </v-window>
        </v-card-text>
    </v-card>
</template>

<script>
import SurveyViewPage from './SurveyViewPage';

export default {
    name: 'SurveyViewDetails',
    components: {SurveyViewPage},
    props: {
        survey: {
            type: Object
        }
    }
};
</script>
