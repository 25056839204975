<template>
    <div class="survey-answer">
        <div v-for="(option, index) in answers[0]" :key="index">
            <strong>{{option.answer}}</strong>
            <br/>
            <small>{{option.estimate}}</small>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AnswerCostEstimate',
    props: {
        answers: {
            required: true
        }
    }
};
</script>
