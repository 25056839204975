<template>
    <v-alert type="info">
        <span style="font-weight:500">{{$t('survey_question_comment.title')}}</span>
        <p>{{question.comment}}</p>
        <span style="font-weight:500">{{$t('survey_question_comment.description')}}</span>

        <answer-cost-estimate v-if="question.inputType === 'COSTESTIMATE'" :answers="question.answers" />
        <answer-media v-if="question.inputType === 'MEDIA'" :answers="question.answers" />
        <answer-option v-if="question.inputType === 'RADIO' || question.inputType === 'CHECKBOX' || question.inputType === 'DROPDOWN'" :answers="question.answers" />
        <answer-date v-if="question.inputType === 'DATE'" :answers="question.answers" />
        <answer-date-time v-if="question.inputType === 'DATETIME'" :answers="question.answers" />
        <answer-damage :answers="question.answers[0]" v-if="question.inputType === 'DAMAGE'"/>
        <answer-text :answers="question.answers" v-if="question.inputType === 'STRING' ||
                                        question.inputType === 'BOOLEAN' ||
                                        question.inputType === 'CALLSIGN' ||
                                        question.inputType === 'IMO' ||
                                        question.inputType === 'NUMERIC' ||
                                        question.inputType === 'CONFIRM' || question.inputType === 'SHIPNAME' "/>
    </v-alert>
</template>

<script>
import AnswerCostEstimate from './Answers/AnswerCostEstimate';
import AnswerMedia from './Answers/AnswerMedia';
import AnswerOption from './Answers/AnswerOption';
import AnswerText from './Answers/AnswerText';
import AnswerDate from './Answers/AnswerDate';
import AnswerDateTime from './Answers/AnswerDateTime';
import AnswerDamage from './Answers/AnswerDamage';

export default {
    name: 'SurveyQuestionInfo',
    components: {AnswerDateTime, AnswerDate, AnswerOption, AnswerMedia, AnswerCostEstimate, AnswerText, AnswerDamage},
    props: {
        question: {
            type: Object
        }
    }
};
</script>
