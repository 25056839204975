<template>
    <v-card flat class="survey__page">
        <v-card-title>{{ getLocalizedValue(page.title) }}</v-card-title>
        <v-card-text class="px-0 pa-0">
            <template v-if="page.children && page.children.length > 0">
                <survey-view-section v-for="section in page.children" :key="section.id" :section="section"/>
            </template>
        </v-card-text>
    </v-card>
</template>

<script>
import SurveyViewSection from './SurveyViewSection';
import GetLocalizedValue from '@/mixins/GetLocalizedValue';

export default {
    name: 'SurveyViewPage',
    props: {
        page: {
            type: Object,
            required: true
        }
    },
    mixins: [
        GetLocalizedValue
    ],
    components: {SurveyViewSection}
};
</script>
